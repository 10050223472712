<template>
  <CFooter>
    <div>
      <a href="https://invenergy.com/" target="_blank">Invenergy Services</a>
      <!--<span> - </span>
      <a href="https://husorenovables.com/" target="_blank">HUSO</a>-->
      <span class="ms-1"
        >&copy; {{ new Date().getFullYear() }}</span
      >
    </div>
    <div class="ms-auto">
      <span class="me-1" target="_blank">Powered by</span>
      <a href="https://balidea.com/">Balidea</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>



// AppBackend.Api.Features.Usuarios.UsuarioDetailsViewModel
export class UsuarioDetails {

  public id: string = "00000000-0000-0000-0000-000000000000";
  public userName: string = null;
  public email: string = null;
  public mustChangePassword: boolean = false;
  public fullName: string = null;
  public empresaId: string = null;
  public codigoOperario: string = null;
  public roles: string[] = [];
}




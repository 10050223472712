export class Login {
  public userName: string;
  public password: string;
  public recordar: boolean;


  public setDefaultValues(): void {
    this.userName = "rene";
    this.password = "123Abc.";
    this.recordar = false;
  }
}


  import { useDialogsStore } from "@/store/common/dialogsStore";
  import { computed } from 'vue';
  export default {
    data() {

      const store = useDialogsStore();

      return {
        visibleAlert: computed(() => store.isLocalAlertVisible),
        titulo: computed(() => store.getTitle),
        msg: computed(() => store.getMsg),
        serverErrors: computed(() => store.getErrors.map(s => s.errors).map(l => l).flat()),
        store
      };

    },
    emits: ['onClose'],
    mounted() {
      //console.log('mounted ServerValidation component');
      this.store.setLocalAlert(true);
    },
    unmounted() {
      // console.log('unmounted ServerValidation component');
      this.store.hideAll();
      this.store.setLocalAlert(false);
    },
    props: {
    },
    methods: {
      hide: function (e) {
        this.store.hideAll();
      },
      clear: function () {
        //Pequeño apaño para limpiar los errores de forma estática
        const s = useDialogsStore();
        s.hideAll();
      }

    }
  }
import { defineStore } from 'pinia'
import {AppSettings} from '@/_config'

export class ValidationErrorCode {
  constructor(public code: string, public message: string) { }
}
export class ValidationError {
  constructor(public fieldName: string, public errors: Array<ValidationErrorCode>) { }
}
export class ValidationData {

  constructor(public title: string, public detail: string, public errors: Array<ValidationError>) {

  }

}
export class ToastContent {
  constructor(public title: string, public rightText: string, public content: string) { }
}
interface IState {
  isVisible: boolean,
  isAlertVisible: boolean,
  isLocalAlertVisible: boolean,
  isLocal: boolean,
  customTitle: string,
  toasts: Array<ToastContent>,
  validationData: ValidationData
}

export const useDialogsStore = defineStore('dialogsStore', {
  state: () => {
    return ({
      isVisible: false,
      isAlertVisible: false,
      isLocalAlertVisible: false,
      isLocal: false,
      customTitle: null,
      validationData: null
      , toasts: []
    } as IState);
  },
  getters:
  {
    getIsVisible: (state) => state.isVisible,
    getIsAlertVisible: (state) => state.isAlertVisible,
    getTitle: (state) => state.customTitle || state.validationData?.title,
    getMsg: (s) => s.validationData?.detail,
    getErrors: (s) => s.validationData?.errors,
    getToast: (s) => s.toasts
  },
  actions: {
    //
    /**
     *Obsolete ya no se tendrá en cuenta este flag. 
     *  - Usar Show400Error o ShowValidationMsg en su defecto
     */
    setLocalAlert(isLocal: boolean) {
      // console.log('setLocalAlert', isLocal);
      this.isLocal = isLocal;
    },
    show400Error(errorData: ValidationData, forzarPopUp: boolean = false) {
      this.validationData = errorData;
      //this.validationData.title = null; //forzamos que ponga error en el título
      this.customTitle = "Error";

      if (this.isLocal && !forzarPopUp) {
        this.isLocalAlertVisible = true;
      } else {
        this.isVisible = true;
      }
    },
    showDialog(data: ValidationData, forzarPopUp: boolean = false) {
      this.validationData = data;
      this.customTitle = null;

      if (this.isLocal && !forzarPopUp) {
        this.isLocalAlertVisible = true;
      } else {
        this.isVisible = true;
      }
    },
    showValidationMsg(data: ValidationData, forzarPopUp: boolean = true) {
      this.validationData = data;
      if (forzarPopUp) {
        this.isVisible = true;
      } else {
        this.isAlertVisible = true;
      }
    },
    hide() {
      this.hideAll(); //TODO hacer cola para ir cerrando uno por uno
      //if (this.isLocal) {
      //  this.isLocalAlertVisible = false;
      //} else {
      //  this.isVisible = false;
      //}
    },
    hideAll() {
      this.isLocalAlertVisible = this.isAlertVisible = this.isVisible = false;
      this.customTitle = null;
    },
    showToast(toast: ToastContent) {
      this.toasts.push(toast);
      setTimeout(self => { self.toasts.shift() }, AppSettings.toastDelay,this)
    }
  }
}
);

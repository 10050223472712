import {
  cilSpeedometer, cilPencil, cilNotes, cilPeople, cilAddressBook,
  cilBuildingBusiness, cilChartTable, cilBookOpen, cilTags, cilLoyalty, cilBellRing,
  cilAvTimer, cilFactory, cilParking, cilBuilding, cilStorage, cilLayers, cilListRich,
  cilGamepad, cilMediaPause, cisApps,
  cilEye, cilLockMinus, cilEnvelopeOpen, cilList, cilBasket, cilChartPie, cilUserFollow, cilUserUnfollow,
  cilMenu, cilLockLocked, cilKey, cilUser
} from '@coreui/icons-pro'

/* definimos los iconos a usar de coreui */
export const iconsSet = Object.assign(
  {},
  {
    cilSpeedometer,
    cilPencil,
    cilNotes,
    cilPeople,
    cilAddressBook,
    cilBuildingBusiness, cilChartTable, cilBookOpen, cilTags, cilLoyalty, cilBellRing,
    cilAvTimer, cilFactory, cilParking, cilBuilding, cilStorage, cilLayers, cilListRich,
    cilGamepad, cilMediaPause, cisApps,
    cilEye, cilLockMinus, cilEnvelopeOpen, cilList, cilBasket, cilChartPie, cilUserFollow, cilUserUnfollow,
    cilMenu, cilLockLocked, cilKey, cilUser
  }
)

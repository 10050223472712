import { defineStore } from "pinia";
import * as Auth from "@/data/auth";

import { markRaw } from 'vue'

//import {useSessionStore} from "@/store/usuarios/sessionStore"
import { useSessionStore } from "./sessionStore"

interface IState {
  db: Auth.AuthDb,
  session: any

}

interface ILoginResult {
  errorMsg: string,

}
export const useUserStore = defineStore('userStore',

  {
    state: () => {

      const session = useSessionStore();

      const db = new Auth.AuthDb();


      return ((({
        db: db,
        session

      }) as any) as IState);
    },
    getters:
    {
      userIsOnlyOperator: (state) => !!state.session.getUserInfo
        && state.session.getUserInfo.roles.split(",").includes(Auth.Roles.Operador)
        && !(state.session.getUserInfo.roles.split(",").includes(Auth.Roles.Supervisor) || state.session.getUserInfo.roles.split(",").includes(Auth.Roles.Administrador))
        ? true : false,
      userIsSupervisor: (state) => (!!state.session.getUserInfo
        && state.session.getUserInfo.roles.split(",").includes(Auth.Roles.Supervisor))
        ? true : false,
      userIsConfigurador: (state) => (!!state.session.getUserInfo
        && state.session.getUserInfo.roles.split(",").includes(Auth.Roles.Configurador))
        ? true : false,
      userIsAdministrator: (state) => (!!state.session.getUserInfo
        && state.session.getUserInfo.roles.split(",").includes(Auth.Roles.Administrador))
        ? true : false,
      getUserRoles: (state) => !!state.session.getUserInfo ? state.session.getUserInfo.roles : [],
      isAuthenticated: (state) => !!state.session.isAuthenticated && !!state.session.getTokenData,
      getUserInfo: (state) => state.session.getUserInfo,
      getUserId: (state) => state.session.getUserInfo?.id,
      getUserEmpresaId: (state) => state.session.getUserInfo?.empresaId,
    },
    actions: {
      async login(loginData: Auth.Login): Promise<ILoginResult> {

        const loginResult = await this.db.login(loginData);
        //console.log('const loginResult', loginResult);

        if (loginResult != null && loginResult.accessToken != null) {
          this.session.setLogOnData(loginData, loginResult);

          await this.loadUserInfo();
        
          return { errorMsg: null };
        }
        else {
          if (!!(loginResult?.errorMsg)) {
            return loginResult
          } //.errorMsg;
          return { errorMsg: "Credenciales incorrectas" }
        }
      },
      async loadUserInfo() {
        // console.log('getInfo', this.db);
        const userInfo = await this.db.getInfo();
        this.session.setUserInfo(userInfo);

        return userInfo;
      },
      logout() {
        // console.log("route instances",this.router,this.router.getRoutes());
        this.session.clearSession();
        this.router.push({ name: 'Login' })
        //this.router.push({ path:'/?#/login?from=/'})
      }
    }
  }
);

<template>
  <CToaster placement="top-end" visible>
    <CToast v-for="(toast, index) in toasts" v-bind:key="index"
            visible
            color="success" class="text-white align-items-center">
      <CToastHeader closeButton>
        <span class="me-auto fw-bold">{{toast.title}}</span>
        <small>{{toast.rightText}}</small>
      </CToastHeader>
      <CToastBody>
        {{ toast.content }}
      </CToastBody>
    </CToast>
  </CToaster>
</template>
<script>
  import { computed } from 'vue'
  import { useDialogsStore } from "@/store/common/dialogsStore";

  export default {
    setup() {
      const dialogs = useDialogsStore()
      return {
        toasts: computed(() => dialogs.getToast)
      }
    },
  }
</script>

import { defineStore } from "pinia";
import { useStorage } from '@vueuse/core'
import * as Auth from "@/data/auth/index";



type LoginInfo = Omit<Auth.Login, "password" | "setDefaultValues">;

interface IState {
  isLogged: boolean,
  isPersistSession: boolean,
  loginInfo: LoginInfo,
  persistTokenInfo: Auth.TokenData,
  sessionTokenInfo: Auth.TokenData,
  userInfo: Auth.UserInfo
}

export const useSessionStore = defineStore('sessionStore',
  {
    state: () => {

      const loginInfo: LoginInfo = { userName: "", recordar: false };
      const userInfo: Auth.UserInfo = { userName: null, email: null, fullName: null, empresaId: null, mustChangePassword: false, roles: null, id: null };
      const tokenInfo: Auth.TokenData = { accessToken: null }

      return ((({
        isLogged: useStorage('isLogged', false),
        isPersistSession: useStorage('isPersistSession', false),
        loginInfo: useStorage("loginInfo", loginInfo),
        persistTokenInfo: useStorage("tokenInfo", tokenInfo),
        sessionTokenInfo: useStorage("tokenInfo", tokenInfo, sessionStorage),
        userInfo: useStorage('userInfo', userInfo),

      })) as any) as IState;

    },
    getters: {
      isAuthenticated: (state) => !!state.isLogged,
      getTokenData: (state) => !!state.isPersistSession ? state.persistTokenInfo : state.sessionTokenInfo,
      getLoginInfo: (state) => state.loginInfo,
      getUserInfo: (state) => state.userInfo
    },
    actions: {

      setLogOnData(loginData: Auth.Login, loginResult: Auth.TokenData) {
        this.loginInfo = {
          userName: loginData.userName,
          recordar: loginData.recordar
        };
        this.isPersistSession = !!loginData.recordar;
        if (!!loginData.recordar) {
          this.persistTokenInfo = loginResult;
        } else {
          this.sessionTokenInfo = loginResult;
        }
        this.isLogged = true;
      }

      ,
      setUserInfo(userInfo: Auth.UserInfo) {
        this.userInfo = userInfo;
      }
      ,
      clearSession() {
        this.isLogged = false;
        this.isPersistSession = false;
        this.sessionTokenInfo = null;
        this.persistTokenInfo = null;
        this.loginInfo = null;
        this.userInfo = null;
      }
    }

  }
);


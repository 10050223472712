
  import { useDialogsStore } from "@/store/common/dialogsStore";
  import { computed } from 'vue';

  export default {
    data() {

      const store = useDialogsStore();

      return {
        visibleDialog: computed(() => store.getIsVisible),
        visibleAlert: computed(() => store.getIsAlertVisible),
        titulo: computed(() => store.getTitle),
        msg: computed(() => store.getMsg),
        serverErrors: computed(() => store.getErrors),
        store
      };

    },
    emits: ['onClose'],
    mounted() {
      // console.log('mounted Dialog');
    },
    props: {
    },
    methods: {      
      hide: function (e) {
        
        this.store.hideAll();        
      }

    }
  }

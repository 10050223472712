<template>
  <AppSidebar />
  <div class="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
    <AppHeader />
    <div class="body flex-grow-1 px-3">
      <CContainer fluid>
        <AppErrorDialog/>
        <router-view />
      </CContainer>
    </div>
    <AppFooter />
    <AppToaster />
  </div>
</template>
<script>
  import { CContainer } from '@coreui/vue-pro'
  import AppFooter from '@/components/AppFooter.vue'
  import AppHeader from '@/components/AppHeader.vue'
  import AppSidebar from '@/components/AppSidebar.vue'
  import AppToaster from '@/components/AppToaster.vue'
  import AppErrorDialog from '@/components/AppErrorDialog.vue'

  export default {
    name: 'DefaultLayout',
    components: {
      AppFooter,
      AppHeader,
      AppSidebar,
      CContainer,
      AppToaster,
      AppErrorDialog
    },
  }
</script>

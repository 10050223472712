
  import { Login } from '@/data/auth/login';
  import { ref, computed } from 'vue';

  import { useUsuariosStore } from "@/store/usuarios/usuariosStore";
  import { useUserStore } from "@/store/usuarios/userStore"
  import { useDialogsStore } from "@/store/common/dialogsStore";
  import { useRouter, useRoute } from 'vue-router'
  import ServerValidation from "@/components/dialogs/ServerValidation.vue"


  export default {
    name: 'Login',
    components: {
      ServerValidation,
    },
    created() {
      //console.log(router);
    },
    data() {

      const validateForm = ref(false);
      const bussy = ref(false);
      const loginData = new Login();
      //const store = stores.useUserStore();
      const store = useUserStore();
      const usuariosStore = useUsuariosStore();
      const dialogs = useDialogsStore()

      const router = useRouter();
      const route = useRoute();

      //mirar si esto está mejor disperso por la aplicación antes de redirigir al login
      // store.logout();

      if (window.location.host.indexOf("localhost") !== -1) loginData.setDefaultValues();

      return {
        validateForm,
        loginData,
        store,
        usuariosStore,
        serverError: '',
        bussy,
        router,
        route,
        dialogs,
        isChangePasswordFormVisible: false,
        changePassword: {
          newPassword: null,
          resetPasswordRepeat1: null,
          resetPasswordRepeat2: null,
          isValidResetPassword: computed(() => (this.changePassword.resetPasswordRepeat1 != '' && this.changePassword.resetPasswordRepeat2 != '' && this.changePassword.resetPasswordRepeat1 == this.changePassword.resetPasswordRepeat2)),
          resetPasswordFeedbackInvalid: computed(() => (this.changePassword.resetPasswordRepeat1 != this.changePassword.resetPasswordRepeat2) ? "Las contraseñas deben coincidir." : "Por favor indique la nueva contraseña."),
        }
      }
    },
    methods: {
      async login(event) {
        event.preventDefault()
        event.stopPropagation()

        this.bussy = true;
        const form = event.currentTarget;
        this.serverError = " ";

        if (form.checkValidity() === false) {
          //console.log('(form.checkValidity() === false)');
          this.validateForm = true
          this.bussy = false;
          return;
        }

        this.validateForm = false;
        const serverResponse = await this.store.login(this.loginData);

        this.serverError = serverResponse.errorMsg;

        console.log('serverError', this.loginData, this.serverError);
        this.bussy = false;

        //convención si no hay error el login fue bueno
        if (!serverResponse.msgError) {

          const userInfo = this.store.getUserInfo;
          if (userInfo.mustChangePassword) {
            this.isChangePasswordFormVisible = true;
          }
          else {
            this.redirectToInitialUrl();
          }
          return;
        }
      },
      formDataChangePasswordIsValid() {
        //set password no null
        if (this.changePassword.resetPasswordRepeat1 == null) this.changePassword.resetPasswordRepeat1 = '';
        if (this.changePassword.resetPasswordRepeat2 == null) this.changePassword.resetPasswordRepeat2 = '';

        //console.log('this.currentPassword', this.currentPassword);
        console.log('this.isValidResetPassword', this.changePassword.isValidResetPassword);
        //validate password
        if (!this.changePassword.isValidResetPassword) {
          this.changePassword.newPassword = null;
          return false;
        } else {
          this.changePassword.newPassword = this.changePassword.newPasswordRepeat1;
          return true;
        }
      },
      async saveNewPassWord() {
        if (!this.formDataChangePasswordIsValid()) {
          return;
        }
        console.log('saveNewPassWord', this.store.getUserId, this.loginData.password, this.resetPasswordRepeat1)
        var res = await this.usuariosStore.updatePasswordAsUser(this.store.getUserId, this.loginData.password, this.changePassword.resetPasswordRepeat1);
        if (res) {
          //redirección a Inicio, o ruta indicada
          this.redirectToInitialUrl();
          this.dialogs.showToast({ title: 'Operación completada', rightText: '', content: 'Contraseña guardada con éxito' });
        }
      },
      redirectToInitialUrl() {
        const current = this.router.currentRoute.value;
        //console.log(this.router, this.router.currentRoute, this.route, current);
        const urlTo = (!!current.query.from) ? current.query.from : '/';
        // console.log(this.router, this.router.currentRoute, this.route, urlTo);
        this.router.push(urlTo);
      }
    }
  }


import { defineStore } from 'pinia'
import * as M from "@/data/usuarios";

import { IPaginatedData } from "@/data/_common/types/paginatedData";


interface IState {
  //items: Array<Almacen>, //to remove
  //totalPages: number,//to remove
  //pageSize: number,//to remove
  //page: number, // to remove
  sortParam: string,
  item: M.UsuarioDetails,
  listData: IPaginatedData<M.Usuario>
}

export const useUsuariosStore = defineStore('usuariosStore',
  {


    state: () => {
      return ({
        //// items: [],
        //totalPages: 0,
        //pageSize: 10,
        //page: 1,
        sortParam: null,
        item: new M.UsuarioDetails(),
        listData: {
          size: 10,
          number: 0,
          numberOfElements: 10,
          totalElements: 0,
          totalPages: 0,
          first: true,
          last: false,
          empty: false,
          content: new Array<M.Usuario>()
        },
      } as IState);
    }
    ,
    getters:
    {
      getListData: (state): IPaginatedData<M.Usuario> => state.listData,
      //  items: (state): any[] => state.items,
      getTotalPages: (state): number => state.listData.totalPages,
    },
    actions: {
      setCurrentPage(page: number, avoidReload: boolean | false) {
        this.listData.number = (page - 1) < 0 ? 0 : (page - 1);
        if (!avoidReload) this.loadItems();
      },
      setItemsPerPage(numItems: number, avoidReload: boolean | false) {
        this.listData.number = 0;
        this.listData.size = numItems;
        if (!avoidReload) this.loadItems();
      },
      setSortedColumn(column: string, order: string | "asc", avoidReload: boolean | false) {
        let sortParam = column;
        if (order === "asc" || order === "desc") sortParam = `${sortParam},${order}`;

        this.sortParam = sortParam;
        if (!avoidReload) this.loadItems();
      },
      loadItems() {
        this.getPaginatedAsync(this.listData.number, this.listData.size, this.sortParam);
      },
      async getPaginatedAsync(page: number | null, size: number | null, sort: Array<string> | null) {

        const db = new M.UsuariosDb();
        const dev = await db.getAllPaginated(page, size, sort);
        this.listData = dev;

      },
      async addItem(item: M.UsuarioNewDetails) {
        const db = new M.UsuariosDb();
        const dev = await db.insert(item);
        this.item = dev;
        this.loadItems();
      },
      async updateItem(id: string, item: M.UsuarioDetails) {
        const db = new M.UsuariosDb();
        const dev = await db.update(id, item);
        this.item = dev;
        this.loadItems();
        return;
      },
      async updateDataPerfil(id: string, item: M.UsuarioDetailsSimple) {
        const db = new M.UsuariosDb();
        await db.updateUserDetailsSimple(id, item);
        return;
      },
      async updatePasswordAsUser(id: string, currentPassword: string, newPassword: string) {
        const db = new M.UsuariosDb();
        const result = await db.updatePasswordAsUser(id, currentPassword, newPassword);
        return result;
      },
      async updatePasswordAsAdmin(id: string, newPassword: string) {
        const db = new M.UsuariosDb();
        const result = await db.updatePasswordAsAdmin(id, newPassword);
        return result;
      },
      async getItemDetails(id: string) {
        const db = new M.UsuariosDb();
        const item = await db.get(id);
        this.item = item;
      },
      clearItemDetails(fullClear: Boolean | false) {
        this.item.id = null;
        if (fullClear) {
          this.item = new M.UsuarioDetails();
        }
      }
    }
  }
);

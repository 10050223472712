import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import Login from '@/views/Login.vue'
import { Roles } from "@/data/auth/roles";

import { useUserStore } from "@/store/usuarios/userStore"
//https://router.vuejs.org/guide/advanced/navigation-guards.html#in-component-guards

const AllRoles = [Roles.Administrador, Roles.Configurador, Roles.Operador, Roles.Supervisor, Roles.Explotador];
const rolesConfigs = [Roles.Administrador, Roles.Configurador];
const rolesOrdenesTrabajo = [Roles.Operador, Roles.Supervisor, Roles.Administrador];
const rolesExplotadores = [Roles.Administrador, Roles.Explotador];
const rolesConsultaAyuda = [Roles.Administrador, Roles.Configurador, Roles.Explotador, Roles.Supervisor, Roles.Operador];
const rolesConfigAyuda = [Roles.Administrador, Roles.Configurador];

const routes: Array<RouteRecordRaw> = [//
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
        meta: {
          roles: AllRoles
        }
      }, {
        path: '/ordenes',
        name: 'Nodo_Ordenes',
        redirect: '/ordenes/ordenes',
        meta: {
          roles: rolesOrdenesTrabajo
        }
      },
      {
        path: '/ordenes/ordenes',
        name: 'Órdenes',
        component: () => import('@/views/ordenes/Ordenes.vue'),
        meta: {
          roles: rolesOrdenesTrabajo
        },
        children: [
          {
            path: '/ordenes/ordenes/ordenDetails',
            name: 'Nueva orden',
            component: () => import('@/views/ordenes/OrdenDetails.vue'),
            meta: {
              roles: rolesOrdenesTrabajo
            }
          },
          {
            path: '/ordenes/ordenes/ordenDetails/:id',
            name: 'Edición órdenes',
            component: () => import('@/views/ordenes/OrdenDetails.vue'),
            meta: {
              roles: rolesOrdenesTrabajo
            }
          }
        ]
      },
      {
        path: '/ordenes/ordenDetails',
        name: 'Nueva orden',
        component: () => import('@/views/ordenes/OrdenDetails.vue'),
        meta: {
          roles: rolesOrdenesTrabajo
        }
      },
      {
        path: '/ordenes/ordenDetails/:id',
        name: 'Edición órdenes',
        component: () => import('@/views/ordenes/OrdenDetails.vue'),
        meta: {
          roles: rolesOrdenesTrabajo
        }
      },
      {
        path: '/ayuda',
        name: 'Ayuda',
        redirect: '/ayuda/documentos',
        meta: {
          roles: rolesOrdenesTrabajo
        }
      },
      {
        path: '/ayuda/documentos',
        name: 'Documentos',
        component: () => import('@/views/documentosayuda/DocumentosAyuda.vue'),
        meta: {
          roles: rolesConsultaAyuda
        }
      },
      {
        path: '/ayuda/categorias',
        name: 'Categorías',
        component: () => import('@/views/maestros/categoriasdocumentos/CategoriasDocumentos.vue'),
        meta: {
          roles: rolesConfigAyuda
        }
      },
      {
        path: '/maestros',
        name: 'Maestros',
        redirect: '/maestros/almacenes',
        meta: {
          roles: rolesConfigs
        }
      },
      {
        path: '/maestros/almacenes',
        name: 'Almacenes',
        component: () => import('@/views/maestros/almacenes/Almacenes.vue'),
        meta: {
          roles: rolesConfigs
        }
      },
      {
        path: '/maestros/clasesTrabajo',
        name: 'Clases de Trabajo',
        component: () => import('@/views/maestros/clasesTrabajo/ClasesTrabajo.vue'),
        meta: {
          roles: rolesConfigs
        }
      },
      {
        path: '/maestros/tecnologias',
        name: 'Tecnologías',
        component: () => import('@/views/maestros/tecnologias/Tecnologias.vue'),
        meta: {
          roles: rolesConfigs
        }
      },
      {
        path: '/maestros/alarmas',
        name: 'Alarmas',
        component: () => import('@/views/maestros/alarmas/Alarmas.vue'),
        meta: {
          roles: rolesConfigs
        }
      },
      {
        path: '/maestros/empresas',
        name: 'Empresas',
        component: () => import('@/views/maestros/empresas/Empresas.vue'),
        meta: {
          roles: rolesConfigs
        }
      },
      {
        path: '/maestros/tiposmateriales',
        name: 'Materiales',
        component: () => import('@/views/maestros/tiposMateriales/TiposMateriales.vue'),
        meta: {
          roles: rolesConfigs
        }
      },
      {
        path: '/maestros/tiposmaterialesconsumibles',
        name: 'Materiales Consumibles',
        component: () => import('@/views/maestros/tiposMaterialesConsumibles/TiposMaterialesConsumibles.vue'),
        meta: {
          roles: rolesConfigs
        }
      },
      {
        path: '/maestros/tiposGruposDeConsumibles',
        name: 'Grupos de Consumibles',
        component: () => import('@/views/maestros/tiposGruposDeConsumibles/TiposGruposDeConsumibles.vue'),
        meta: {
          roles: rolesConfigs
        }
      },
      {
        path: '/maestros/tiposGruposDeConsumibles',
        name: 'Nuevo Grupo de Consumibles',
        component: () => import('@/views/maestros/tiposGruposDeConsumibles/TipoGrupoDeConsumiblesDetails.vue'),
        meta: {
          roles: rolesConfigs
        }
      },
      {
        path: '/maestros/tiposGruposDeConsumibles/:id',
        name: 'Edición Grupo de Consumibles',
        component: () => import('@/views/maestros/tiposGruposDeConsumibles/TipoGrupoDeConsumiblesDetails.vue'),
        meta: {
          roles: rolesConfigs
        }
      },
      {
        path: '/maestros/herramientas',
        name: 'Herramientas',
        component: () => import('@/views/maestros/herramientas/Herramientas.vue'),
        meta: {
          roles: rolesConfigs
        }
      },
      {
        path: '/maestros/parques',
        name: 'Parques',
        component: () => import('@/views/maestros/parques/Parques.vue'),
        meta: {
          roles: rolesConfigs
        }
      },
      {
        path: '/maestros/tipoHora',
        name: 'Tipos Hora',
        component: () => import('@/views/maestros/tipohora/TipoHora.vue'),
        meta: {
          roles: rolesConfigs
        }
      },
      {
        path: '/maestros/clientes',
        name: 'Clientes',
        component: () => import('@/views/maestros/clientes/Clientes.vue'),
        meta: {
          roles: rolesConfigs
        }
      },
      {
        path: '/maestros/motivosParada',
        name: 'Motivos Parada',
        component: () => import('@/views/maestros/motivosParada/MotivosParada.vue'),
        meta: {
          roles: rolesConfigs
        }
      },
      {
        path: '/admin',
        name: 'Admin',
        redirect: '/admin/usuarios',
        meta: {
          roles: [Roles.Administrador]
        }
      },
      {
        path: '/admin/usuarios',
        name: 'Usuarios',
        component: () => import('@/views/admin/Usuarios.vue'),
        meta: {
          roles: [Roles.Administrador]
        },
        //children: [
        //  {
        //    path: '/admin/usuarios/usuarioDetails',
        //    name: 'Nuevo usuario',
        //    component: () => import('@/views/admin/UsuarioDetails.vue'),
        //    meta: {
        //      roles: [Roles.Administrador]
        //    }
        //  },
        //  {
        //    path: '/admin/usuarios/usuarioDetails/:id',
        //    name: 'Edición usuarios',
        //    component: () => import('@/views/admin/UsuarioDetails.vue'),
        //    meta: {
        //      roles: [Roles.Administrador]
        //    }
        //  }
        //]
      },
      {
        path: '/admin/usuarios/usuarioDetails',
        name: 'Nuevo usuario',
        component: () => import('@/views/admin/UsuarioDetails.vue'),
        meta: {
          roles: [Roles.Administrador]
        }
      },
      {
        path: '/admin/usuarios/usuarioDetails/:id',
        name: 'Edición usuarios',
        component: () => import('@/views/admin/UsuarioDetails.vue'),
        meta: {
          roles: [Roles.Administrador]
        }
      },
      {
        path: '/admin/usuarios/usuarioPerfil',
        name: 'Perfil usuario',
        component: () => import('@/views/admin/UsuarioPerfilDetails.vue'),
        meta: {
          roles: AllRoles
        }
      },
      {
        path: '/admin/usuarios/cambiarContrasena',
        name: 'Cambiar contraseña',
        component: () => import('@/views/admin/CambiarContrasena.vue'),
        meta: {
          roles: AllRoles
        }
      },
      {
        path: '/informes',
        name: 'Explotación',
        redirect: '/admin/usuarios',
        meta: {
          roles: rolesExplotadores
        }
      },
      {
        path: '/informes/informes',
        name: 'Informes',
        component: () => import('@/views/informes/Informes.vue'),
        meta: {
          roles: rolesExplotadores
        }
      }
    ],


  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      allowAnonymous: true
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const intersect = (arr1, arr2) => arr1 == null || arr2 == null
    ? []
    : arr1.filter(w => arr2.includes(w));
  const store = useUserStore();

  //Nombres de rutas donde no se tendrán en cuenta restricciones de autorización y se evitan los cálculos de acceso
  const routesToAllow = ['Login'];

  //Urls relativas donde no se tendrán en cuenta restricciones de autorización y se evitan los cálculos de acceso
  const pathToAllow = ['/login'];

  const goToLogin = () => {
    const currentPath = `${window.location.pathname}${window.location.hash.replace('#/', '') }`;
    //console.log('goToLogin', currentPath, to, from, 'to.meta.roles, store.getUserRoles,!!to.meta.roles , !!to.meta.allowAnonymous'
    //  , to.meta.roles, store.getUserRoles, !!to.meta.roles,
    //  !!to.meta.allowAnonymous, store.isAuthenticated);
    return next({ name: 'Login', query: { from: currentPath } });
    //next();
    return;
  }

  //console.log(window.location.pathname, 'beforeEach_1', to, from, 'to.meta.roles, store.getUserRoles,!!to.meta.roles , !!to.meta.allowAnonymous'
  //  , to.meta.roles, store.getUserRoles, !!to.meta.roles, !!to.meta.allowAnonymous, store.isAuthenticated);

  //si no tiene metadatos asumimos que es pública, lo dejamos pasar como las de allowanonimous. Lo mejro indicarlo explícitamente
  if (routesToAllow.indexOf(to.name.toString()) !== -1
    || pathToAllow.indexOf(to.path) !== -1
    || !to.meta
    || !!to.meta.allowAnonymous) {
    next();
    return;
  }

  //require algún rol, usuarios anónimos no pasan
  if (!!to.meta.roles && !store.isAuthenticated) {
    //console.warn(to, store.isAuthenticated, "!!to.meta.roles && !store.isAuthenticated");
    goToLogin();
    return;
  }

  const match = intersect(to.meta.roles, store.getUserRoles);

  if (match.length === 0) {
    goToLogin();
    return;
  }

  next();

});

export default router

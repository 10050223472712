<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand class="mx-auto d-none" to="/">
        <!--<CIcon :icon="logo" height="48" alt="Logo" />-->
        <a class="header-brand" href="/#/dashboard">
          <img src="@/assets/brand/logo-invenergy-services.png"
               alt="Invenergy Services"
               class="d-inline-block align-top d-lg-flex">
        </a>
      </CHeaderBrand>
      <CHeaderNav class="me-auto me-4">
        <CNavItem>
          <!--<CNavLink href="/dashboard"> Panel inicial </CNavLink>-->
          <!--HACK: DEV - Responsive tags -->
          <BreakPointViewer class="d-none"></BreakPointViewer>
        </CNavItem>
      </CHeaderNav>
      <CHeaderNav class="me-4">
        <!-- <AppHeaderDropdownNotif />
        <AppHeaderDropdownTasks />
        <AppHeaderDropdownMssgs />-->
      </CHeaderNav>
      <CHeaderNav class="ms-3 me-4">
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
    <CHeaderDivider />
    <CContainer fluid>
      <AppBreadcrumb />
    </CContainer>
  </CHeader>
</template>

<script>
  import AppBreadcrumb from './AppBreadcrumb'
  import AppHeaderDropdownAccnt from './webparts/AppHeaderDropdownAccnt'
  //import AppHeaderDropdownMssgs from './webparts/AppHeaderDropdownMssgs'
  //import AppHeaderDropdownNotif from './webparts/AppHeaderDropdownNotif'
  //import AppHeaderDropdownTasks from './webparts/AppHeaderDropdownTasks'
 // import { logo } from './../assets/brand/logo'

  import BreakPointViewer from './utils/BreakPointViewer.vue'

  export default {
    name: 'AppHeader',
    components: {
      AppBreadcrumb,
      AppHeaderDropdownAccnt,
      //AppHeaderDropdownMssgs,
      //AppHeaderDropdownNotif,
      //AppHeaderDropdownTasks,
      BreakPointViewer
    },
    setup() {
      return {
        //logo,
      }
    },
  }
</script>

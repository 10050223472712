/*
 * HELPS: 
 *    https://github.com/axios/axios#interceptors
 *    https://dev.to/charlintosh/setting-up-axios-interceptors-react-js-typescript-12k5
 *    https://github.com/axios/axios/issues/1510
 * TODO:
 *  - Implementar la captura del token desde aquí y la config by default
 *   
 *
 */

import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { useDialogsStore, ValidationData, ValidationError, ValidationErrorCode } from "@/store/common/dialogsStore";

import { AppSettings } from "@/_config";
import { useSessionStore } from "@/store/usuarios/sessionStore"
import { useUserStore } from "@/store/usuarios/userStore"

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {

  //const token = TokenManager.getTokenData();
  const session = useSessionStore();
  const token = session.getTokenData;

  if (token != null) {
    config.headers["Authorization"] = `Bearer ${token.accessToken}`;
  }
  if (window.location.host.indexOf("localhost") !== -1) {
    console.debug(`[request] [${JSON.stringify(config)}]`);
  }

  return config;
}

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  if (window.location.host.indexOf("localhost") !== -1) {
    console.error(`[request error] [${JSON.stringify(error)}]`);
  }
  return Promise.reject(error);
}

const onResponse = (response: AxiosResponse): AxiosResponse => {
  // console.info(`[response] [${JSON.stringify(response)}]`);
  return response;
}

const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  //console.error(`[response error] [${JSON.stringify(error)}]`, error);

  if (error.response == undefined || error.response == null) {
    //TODO: [HUSO] manejar error timeout (sin response)
    console.error(`[response error] [${JSON.stringify(error)}]`, error);
  }
  else {
    const store = useDialogsStore();
    switch (error.response.status) {
      case 400:
        let d = error.response.data as any;

        //parseo errores de llamadas que devuelven blob (descargas e informes)
        //console.warn('error.response.data', error.response.data instanceof Blob, error.response.headers["content-type"]);
        const isJsonBlob = (response) => response.data instanceof Blob
          && (response.headers["content-type"].includes("application/json") || response.headers["content-type"].includes("application/problem+json"));
        if (isJsonBlob(error.response)) {
          const blob = error.response.data as Blob;
          await blob.text().then(text => {
            d = JSON.parse(text);
          });
        }

        //console.log('d.error', d.errors, JSON.stringify(d.errors));
        const errors = Array<ValidationError>();

        if (!!d.customError) {
          for (const fieldName in d.errors) {
            if (Object.prototype.hasOwnProperty.call(d.errors, fieldName)) {
              const l = new ValidationError(fieldName, []);
              for (let errCode of d.errors[fieldName]) {
                l.errors.push(new ValidationErrorCode(errCode.code, errCode.message));
              }

              errors.push(l)
              // console.log(fieldName,l,errors);
            }
          }
        }
        const detail = (!!d.customError ? d.detail : "Error inesperado");
        //console.warn('detail', d.detail);

        const ve = new ValidationData(d.title, detail, errors);
        const forzarPopUp = error.config.method === "delete";
        store.show400Error(ve, forzarPopUp);
        break;
      case 401:
        const userStore = useUserStore();
        userStore.logout();
        if (window.location.host.indexOf("localhost") !== -1) {
          console.error('axiosError', error);
        }
        break;
      case 403:
        const vd403 = new ValidationData("Atención", "Operación no permitida.", null);
        store.showDialog(vd403, true);
        if (window.location.host.indexOf("localhost") !== -1) {
          console.error('axiosError', error);
        }
        break;
      case 404:
        const vd404 = new ValidationData("Atención", "No se encontró un registro o registros coincidentes.", null);
        store.showDialog(vd404, true);
        break;
      default:
        const vdDefault = new ValidationData("Error inesperado", "Se ha producido un error en la aplicación. Si el problema persiste póngase en contacto con el administrador.", null);
        store.showDialog(vdDefault, true);

        if (window.location.host.indexOf("localhost") !== -1) {
          console.error('axiosError', error);
        }
        break;
    }
  }
  // store.show400Error(error)


  return Promise.reject(error);
}

// No uses esta función, utiliza la instancia de AxiosSetup para crear una instancia independiente de Axios por cada Db
export function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
  // axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}


export class AxiosSetup {

  private instance: AxiosInstance;
  public apiUrl: string;

  constructor(public apiName: string, protected baseUrl: string = null) {

    this.baseUrl = this.baseUrl || AppSettings.apiBaseUrl;
    this.apiUrl = `${this.baseUrl}${apiName}/`;
  }

  public createInstance(axiosInstance: AxiosInstance = null): AxiosSetup {

    this.instance = axiosInstance ??
      axios.create({
        baseURL: this.apiUrl,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }
      });

    return this;
  }

  public setDefaultResponseInterceptors(): AxiosSetup {
    this.instance.interceptors.response.use(onResponse, onResponseError);
    return this;
  }

  public setDefaultRequestInterceptors(): AxiosSetup {
    this.instance.interceptors.request.use(onRequest, onRequestError);
    return this;
  }

  public build(): AxiosInstance {
    return this.instance;
  }

  public buildWithDefaultValues(): AxiosInstance {
    return this
      .createInstance()
      .setDefaultRequestInterceptors()
      .setDefaultResponseInterceptors()
      .build();
  }
}

import axios, { AxiosError } from 'axios';
import { Login } from "@/data/auth/login";
import { TokenData } from "@/data/auth/token";
import { UserInfo } from "@/data/auth/userInfo";
import { BaseRest } from "@/data/_common/base/BaseRest";

export class AuthDb extends BaseRest {
  constructor() {
    super("Auth");
    //this.apiBaseUrl = `${this.apiBaseUrl}Auth/`;
  }

  public async login(credentials: Login) {
    //const opts = this.getRequestOptions();

    //  opts.params = { id: id };
    const method = 'Token';
    //const { data, status } = await axios.post<TokenData>(method, credentials, opts)
    try {
      //const { data, status } = await axios.post<TokenData>(method, credentials, opts);
      const { data, status } = await this.http.post<TokenData>(method, credentials);
      //console.log('login result', data, status);
      return data;

    } catch (error) {
      //console.log('login error', error, error as AxiosError);

      if (axios.isAxiosError(error)) {
        const err = error as AxiosError
        if (err.response == undefined || err.response == null) {
          //console.error(`[response err] [${JSON.stringify(err)}]`, err);
          return { errorMsg:'Error inesperado'};
        } else if (err.response.status === 401) {
          return { errorMsg:'Credenciales incorrectas'};
        }
        //console.log('axios error', error, err, err.response.status);
      } else {
          //console.log('generic error', error);
          return { errorMsg:'Error inesperado'};
      }
    }
  };

  public async getInfo() {
    //const opts = this.getRequestOptions();
    const method = 'Info';
    //const { data, status } = await axios.get<UserInfo>(method, opts)
    const { data, status } = await this.http.get<UserInfo>(method)

    return data;
  }



}

import { createApp,markRaw } from 'vue'
import { createPinia } from 'pinia'
import { Router } from 'vue-router'


import CoreuiVue from '@coreui/vue-pro'
import { CIcon } from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
///////OJO!! no importamos todos usamos  '@/assets/icons' para evitar que aumente el tamaño del chunk-vendors.js en publicación
///////import * as icons from '@coreui/icons-pro';

//https://vue3datepicker.com/installation/#global 
import '@vuepic/vue-datepicker/dist/main.css'
import Datepicker from '@vuepic/vue-datepicker';



import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { library, dom} from "@fortawesome/fontawesome-svg-core";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';


import App from './App.vue'
import router from './router'
import store from './store'

library.add(fas, far, fab)

dom.watch();

const app = createApp(App)
app.use(router)


//added pinia
//TODO: llevar las custom de pinia a un archivo de tipos
//https://pinia.vuejs.org/core-concepts/plugins.html#typing-new-store-properties 
declare module 'pinia' { export interface PiniaCustomProperties { router: Router } }
const pinia = createPinia()
pinia.use(({ store }) => { store.router = markRaw(router) });

app.use(pinia)


app.use(store)

app.use(CoreuiVue)
app.provide('icons', icons)

app.component('CIcon', CIcon)
app.component('Datepicker', Datepicker)
app.component('fa-icon', FontAwesomeIcon)

app.mount('#app')

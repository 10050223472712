import { createStore } from 'vuex'

export default createStore({
  state: {
    asideVisible: false,
    sidebarVisible: true,
    sidebarUnfoldable: false,
    theme: 'default',
    toasts: []
  },
  mutations: {
    toggleAside(state) {
      state.asideVisible = !state.asideVisible
    },
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleTheme(state, payload) {
      state.theme = payload.value
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    createToast(state, { title, rightText, content }) {
      //console.log("createToast", title, rightText, content);
      state.toasts.push({
        title: title,
        rightText: rightText,
        content: content
      })
    }
  },
  actions: {},
  modules: {},
})

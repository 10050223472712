"use strict";
import { AxiosInstance } from 'axios';
import { AxiosSetup } from "./axiosSetup";
import { AppSettings } from "@/_config";

export class BaseRest {

  public apiUrl: string;

  protected apiBaseUrl: string = AppSettings.apiBaseUrl; //  process.env.VUE_APP_API_URL + "/api/";

  protected readonly http: AxiosInstance;

  constructor(public apiName: string) {
    // console.log('BaseRest Constructor', this);

    //setupInterceptorsTo(axios);
    const builder = new AxiosSetup(apiName);
    this.http = builder.buildWithDefaultValues();
    this.apiUrl = builder.apiUrl;

  }
  
}

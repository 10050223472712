export default [
  //{
  //  component: 'CNavItem',
  //  name: 'Inicio',
  //  to: '/dashboard',
  //  icon: 'cil-home',
  //},
  {
    component: 'CNavTitle',
    name: 'Órdenes',
    color: { bgcolor: 'primary', textColor: 'white' }/* dashboard color */
  },
  {
    component: 'CNavItem',
    name: 'Órdenes',
    to: '/ordenes/ordenes',
    icon: 'cil-notes',
  },
  {
    component: 'CNavItem',
    name: 'Nueva Orden',
    to: '/ordenes/ordenDetails',
    icon: 'cil-pencil',
  },
  {
    component: 'CNavTitle',
    name: 'Explotación',
    color: { bgcolor: 'success', textColor: 'white' }/* dashboard color */
  },
  {
    component: 'CNavItem',
    name: 'Informes',
    to: '/informes/informes',
    icon: 'cil-chart-table',
  },
  {
    component: 'CNavTitle',
    name: 'Ayuda',
    color: { bgcolor: 'warning', textColor: 'white' }/* dashboard color */
  },
  {
    component: 'CNavItem',
    name: 'Documentos',
    to: '/ayuda/documentos',
    icon: 'cil-book-open',
  },
  {
    component: 'CNavItem',
    name: 'Categorías',
    to: '/ayuda/categorias',
    icon: 'cil-tags',
  },
  {
    component: 'CNavTitle',
    name: 'Maestros',
    color: { bgcolor: 'dark', textColor: 'white' }/* dashboard color */
  },
  {
    component: 'CNavItem',
    name: 'Clases de Trabajo',
    to: '/maestros/clasesTrabajo',
    icon: 'cil-loyalty',
  },
  {
    component: 'CNavItem',
    name: 'Tecnologías',
    to: '/maestros/tecnologias',
    icon: 'cis-apps',
  },
  {
    component: 'CNavItem',
    name: 'Alarmas',
    to: '/maestros/alarmas',
    icon: 'cil-bell-ring',
  },
  {
    component: 'CNavItem',
    name: 'Tipos Hora',
    to: '/maestros/tipoHora',
    icon: 'cil-av-timer',
  },
  {
    component: 'CNavItem',
    name: 'Empresas',
    to: '/maestros/empresas',
    icon: 'cil-factory',
  },
  {
    component: 'CNavItem',
    name: 'Clientes',
    to: '/maestros/clientes',
    icon: 'cil-address-book',
  },
  {
    component: 'CNavItem',
    name: 'Parques',
    to: '/maestros/parques',
    icon: 'cil-parking',
  },
  {
    component: 'CNavItem',
    name: 'Almacenes',
    to: '/maestros/almacenes',
    icon: 'cil-building',
  },
  {
    component: 'CNavItem',
    name: 'Materiales',
    to: '/maestros/tiposmateriales',
    icon: 'cil-storage',
  },
  {
    component: 'CNavItem',
    name: 'Materiales Consumibles',
    to: '/maestros/tiposmaterialesconsumibles',
    icon: 'cil-layers',/*cil-filter-square */
  },
  {
    component: 'CNavItem',
    name: 'Grupos de Consumibles',
    to: '/maestros/tiposgruposdeconsumibles',
    icon: 'cil-list-rich',
  },
  {
    component: 'CNavItem',
    name: 'Herramientas',
    to: '/maestros/herramientas',
    icon: 'cil-gamepad',
  },
  {
    component: 'CNavItem',
    name: 'Motivos Parada',
    to: '/maestros/motivosParada',
    icon: 'cil-mediaPause',
  },
  {
    component: 'CNavTitle',
    name: 'Admin',
    color: { bgcolor: 'danger', textColor: 'white' }/* dashboard color */
  },
  {
    component: 'CNavItem',
    name: 'Usuarios',
    to: '/admin/usuarios',
    icon: 'cil-people',
  }
]
import { useRouter } from 'vue-router'
import { useUserStore } from "@/store/usuarios/userStore";

export class NavUtils {
  userStore: any;
  rutas;
  router;
  userRoles;

  constructor() {
    this.userStore = useUserStore();
    //this.userRoles = this.userStore.userRoles;

    this.router = useRouter()
    this.rutas = this.router.getRoutes();
    //console.log('constructor', this.rutas, this.userRoles)
  }

  getUserRoles() {
    return this.userStore.getUserRoles;
  }

  public hasAuth(item) {
    this.userRoles = this.getUserRoles();

    // console.log('hasAuth(item)', item, this.rutas, this.userRoles)
    const normalize = (str) => str.normalize('NFD')
      .replace(/[\u0300-\u036f]/g, "")
      .replaceAll(' ', '')
      .toUpperCase();

    const intersect = (arr1, arr2) => arr1 == null || arr2 == null
      ? []
      : arr1.filter(w => arr2.includes(w));

    const ruta = this.rutas.filter(w => normalize(w.name) === normalize(item.name))[0];

    if (!ruta || !ruta.hasOwnProperty('meta')) return true;

    if (!!ruta.meta.allowAnonymous || !ruta.meta.roles) return true;

    //si el link require auth pero el usuairo no tiene ningún rol(no logueado) ya no buscamos permisos
    if (!this.userRoles || this.userRoles.length === 0) return false;

    const rolesMatch = intersect(ruta.meta.roles, this.userRoles);
    //console.log('rolesMatch', item, ruta, this.rutas, this.userRoles, rolesMatch)
    return rolesMatch.length > 0;
  }

}



<template>
  <div style="color:red">
    <span class="d-block d-sm-none">xs</span>
    <span class="d-none d-sm-block d-md-none">sm</span>
    <span class="d-none d-md-block d-lg-none">md</span>
    <span class="d-none d-lg-block d-xl-none">lg</span>
    <span class="d-none d-xl-block">xl</span>
  </div>
</template>


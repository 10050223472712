import { Usuario, UsuarioSimple, UsuarioNewDetails, UsuarioDetails, UsuarioDetailsSimple } from "./";
import { BaseRest } from "@/data/_common/base/BaseRest";
import { IPaginatedData, PaginatedOptions } from "@/data/_common/types/paginatedData";

export class UsuariosDb extends BaseRest {

  constructor() {
    super("usuarios");
  }

  public async get(id: string): Promise<UsuarioDetails> {
    const method = `${id}`;
    const { data, status } = await this.http.get<UsuarioDetails>(method)

    return data;
  }

  public async getAllPaginated(page: number, size: number, sort: string[], auxParams: any = null): Promise<IPaginatedData<Usuario>> {
    //const opts = this.getRequestOptions();
    const opts = { params: { page: page || 0, size: size || 10, sort: sort } };

    //const { data, status } = await axios.get<IPaginatedData<TEntity>>('Paginado', opts)
    const { data, status } = await this.http.get<IPaginatedData<Usuario>>('Paginado', opts)

    //return data as IPaginatedData<Almacen>;
    return new Promise((resolve, reject) => {
      resolve(data);
    });

  }

  public async getAllOperators(): Promise<Array<UsuarioSimple>> {

    const { data, status } = await this.http.get<Array<UsuarioSimple>>('/Operadores/')

    return data;
  }

  public async getOperatorsByEmpresa(empresaId: string): Promise<Array<UsuarioSimple>> {

    const param = `${empresaId}`;
    const { data, status } = await this.http.get<Array<UsuarioSimple>>('/Operadores/PorEmpresa/' + param)

    return data;
  }

  public async getAllSupervisors(): Promise<Array<UsuarioSimple>> {

    const { data, status } = await this.http.get<Array<UsuarioSimple>>('/Supervisores/')

    return data;
  }


  //*******Insert/update*********

  public async insert(viewModel: UsuarioNewDetails): Promise<UsuarioDetails> {
    const { data, status } = await this.http.post<UsuarioDetails>("", viewModel);

    return new Promise((res, rej) => {
      res(data)
    });
  }

  public async update(id: string, viewModel: UsuarioDetails): Promise<UsuarioDetails> {
    //console.log(viewModel.codigoOperario);
    //console.log('viewModel',viewModel);
    const method = `${id}`;
    const { data, status } = await this.http.put<UsuarioDetails>(method, viewModel);

    return new Promise((res, rej) => {
      res(data)
    });
  }

  public async updateUserDetailsSimple(id: string, viewModel: UsuarioDetailsSimple): Promise<UsuarioDetails> {
    //console.log('viewModel', viewModel);
    const method = `${id}/UpdateAsUser`;
    const { data, status } = await this.http.put<UsuarioDetails>(method, viewModel);

    return new Promise((res, rej) => {
      res(data)
    });
  }

  public async updatePasswordAsUser(id: string, currentPassword: string, newPassword: string): Promise<boolean> {
    const method = `${id}/CambioPasswordAsUser`;
    const params = { currentPassword: currentPassword, newPassword: newPassword }
    //console.log('params', params);
    const { data, status } = await this.http.put<boolean>(method, params);

    return new Promise((res, rej) => {
      res(data)
    });
  }

  public async updatePasswordAsAdmin(id: string, newPassword: string): Promise<boolean> {
    const method = `${id}/CambioPasswordAsAdmin`;
    const params = { newPassword: newPassword }
    const { data, status } = await this.http.put<boolean>(method, params);

    return new Promise((res, rej) => {
      res(data)
    });
  }
}

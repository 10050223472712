<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false" href="javascript:void(0);">
      <span class="user-full-name">
        {{store.getUserInfo?.fullName ?? store.getUserInfo?.userName ?? "Anonimo"}}
      </span>
      <CAvatar :src="avatar" size="md" status="success" />

    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Cuenta
      </CDropdownHeader>
      <CDropdownItem @click="$router.push({ name: 'Perfil usuario'});">
        <CIcon icon="cil-user" class="text-success" />
        Perfil Usuario
      </CDropdownItem>
      <CDropdownItem @click="$router.push({ name: 'Cambiar contraseña'});">
        <CIcon icon="cil-key" class="text-warning" />
        Cambiar Contraseña
      </CDropdownItem>

      <CDropdownDivider />
      <!--<CDropdownItem href="/?#/Login" >-->
      <CDropdownItem href="#" @click="logOut">
        <CIcon icon="cil-lock-locked" class="text-danger" />
        Cerrar Sesión
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>
<script>
  import avatar from '@/assets/images/user_icon.png'
  import { useUserStore } from "@/store/usuarios/userStore"
  export default {
    name: 'AppHeaderDropdownAccnt',
    setup() {
      const store = useUserStore();
      store.loadUserInfo();

      return {
        avatar: avatar,
        store
      }
    },
    methods: {
      logOut() {       
        console.info('logOut');
        this.store.logout();
      }
    }
  }
</script>
<style lang="scss">
  .user-full-name {
    margin-right: 10px;
    // margin-top: 3px;
  }
</style>
